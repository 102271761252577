<template>
  <div class="pa-2">
    <v-row align="center" justify="center" dense class="mb-4">
      <v-avatar color="success">
        <span class="white--text text-h5">{{ avatar }}</span>
      </v-avatar>
    </v-row>
    <v-row align="center" justify="center" dense>
      <div>{{ name }}</div>
    </v-row>
    <v-row align="center" justify="center" dense>
      <div>{{ email }}</div>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["name", "avatar", "email"])
  }
};
</script>
